export const USERS_PROFILE_ROUTE = '/users/profile';
export const USERS_CHANGE_PASSWORD = '/users/change_password';
export const USERS_ROUTE = '/users';
export const USERS_MP_ROUTE = '/users/mp';
export const USERS_CO_ROUTE = '/users/co';
export const USERS_SERVICE_ROUTE = '/users/service';
export const USERS_WAIT_APPROVE = '/users/waiting'; // no idea why this is started with "/users"
export const ROLE_ROUTE = '/users/role';
export const OPERATOR_ROUTE = '/users/operator';
export const USER_CP_ROUTE = '/users/cp';
export const USERS_CONTENT_PROVIDER_ROUTE = '/users/cp_admin';
export const CP_ROUTE = '/cp';
export const SERVICE_OPERATOR_ROUTE = '/users/service_operator';

export const CONTENT_ROUTE = '/content';
export const CONTENT_IMPORT_ROUTE = '/content/import';
export const CONTENT_IMPORT_FILE_ROUTE = '/content/import_file';
export const CONTENT_IMPORT_QUIZ_ROUTE = '/content/import_quiz';
export const CONTENT_IMPORT_FILE_QUIZ_ROUTE = '/content/import_file_quiz';

export const CONTENT_OWR_ROUTE = '/content_owners';
export const CONTENT_CATEGORY_ROUTE = '/content_owners/category';
export const CONTENT_OWR_FORMAT_TYPE = '/content_owners/format_type';

export const CUSTOMER_SUBSCRIPTION_ROUTE = '/customer/subscriptions';
export const CUSTOMER_SUBSCRIPTION_CONTENT_ROUTE = '/customer/subscriptions/content';
export const CUSTOMER_SUBSCRIPTION_HISTORY = '/customer/subscriptions/history';
export const CUSTOMER_LOGS_ROUTE = '/customer/find_log';
export const CANCEL_CUSTOMER_SUBSCRIPTION_LIST_ROUTE =
  '/customer/subscriptions/cancel';
export const CUSTONER_OWR_SHORTCODE_ROUTE = '/content/owner_short_code';
export const CUSTOMER_BLOCK_LIST_ROUTE = '/customer/add_blocklist';
export const CUSTOMER_BLACK_LIST_ROUTE = '/customer/add_blacklist';
export const CUSTOMER_STEAM_FILE_VOICE = '/customer/stream_file_voice';

export const SERVICE_CONTENT_LIST_ROUTE = '/content/service_content_list';

export const AUTH_LOGIN_ROUTE = '/auth/login';
export const AUTH_REGISTER_ROUTE = '/auth/register';
export const AUTH_FORGOT_ROUTE = '/auth/forgot_password';
export const AUTH_RESET_PASSWORD = '/auth/reset_password';
export const AUTH_VERIFY_TOKEN = '/auth/verify_token';

export const USER_SERVICE = '/users/service';
export const REPORTS_TRAFFIC_ROUTE = '/reports/traffic';
export const REPORTS_SUBSCRIPTION_ROUTE = '/reports/subscription';
export const REPORTS_TRANSACTION_ROUTE = '/reports/transaction';
export const REPORTS_REVENUE_ROUTE = '/reports/revenue';
export const REPORTS_CONTENT_DELIVERED_ROUTE = '/reports/follow_transaction';
export const REPORTS_HEALTH_ROUTE = 'reports/health_check';
export const REPORTS_ERROR_ROUTE = 'reports/error_code';
export const REPORTS_SMS_BROADCAST = 'reports/sms_broadcast_reports';
export const REPORTS_FINANCIAL = 'reports/financial';
export const REPORTS_VOTE = 'reports/vote_reports';
export const REPORTS_SUBSCRIBER = 'reports/subscriber';
export const REPORTS_PERFORMANCE = '/reports/report_performance';
export const REPORTS_TOPUP = '/reports/report_topup';
export const ERROR_CODE_MASTER = 'error_code/error_code_master';

export const USER_SP_ACCOUNT = '/users/sp/account';
export const USER_SP_OPERATOR = '/users/sp/operator';
export const USER_SP_MP = '/users/sp/mp';
export const USER_SP_SERVICE = '/users/sp/service';
export const ADMIN_REPORTS_SUBSCRIPTION_ROUTE = '/reports/subscription/sp';
export const ADMIN_REPORTS_TRANSACTION_ROUTE = '/reports/transaction/sp';

export const SERVICES_ROUTE = '/services';
export const SERVICES_CREATE_ROUTE = '/services/create';
export const SERVICES_EDIT_ROUTE = '/services/edit';
export const SERVICES_UPDATE_ROUTE = '/services/update';
export const SERVICES_IMAGE_CREATE_ROUTE = '/services/upload_image';
export const SERVICES_IMAGE_REMOVE_ROUTE = '/services/remove_image';
export const SERVICES_HISTORY_DETAIL_ROUTE = '/services/history_detail';
export const SERVICES_HISTORY_ROUTE = '/services/history';
export const SERVICES_FILE_CREATE_ROUTE = '/services/upload_file';
export const SERVICES_FILE_REMOVE_ROUTE = '/services/remove_file';
export const SERVICES_ACTION = '/services/action';
export const SERVICES_SUB_CHECKCALLBACK = '/services/check_subscription_url';
export const SERVICES_DR_CHECKCALLBACK = '/services/check_callback';

export const SERVICES_SUBSCRIPTION_CALLBACK_URL =
  '/services/subscription_callback_url';
export const SERVICES_CHECK_URL = '/services/subscription_check_url';
export const SERVICES_UPDATE_SERVICE_URL = '/services/update_service_url';

export const FREE_SERVICES_ROUTE = '/free_services';

export const BLOCKLIST_ROUTE = '/blocklist';
export const WHITELIST_ROUTE = '/whitelist';
export const BLACKLIST_ROUTE = '/black_list/blacklist';
export const BLACKLIST_HISTORY_ROUTE = '/black_list/blacklist_history';

export const FILE_LIST = 'file/list';
export const CHARGE_TYPES = '/charge_types';

export const TELESALES_VOICES = '/telesales/voices';
export const TELESALES_VOICES_STREAM = '/telesales/voices/stream';
export const TELESALES_VOICES_CONFIRM = '/telesales/voices/confirm';

export const SMS_BROADCAST_UPLOAD_FILE = '/sms_broadcast/uploadFile';
export const SMS_BROADCAST_BULK = '/sms_broadcast/bulk';
export const SMS_BROADCAST_TOTALS_MSISDN = '/sms_broadcast/sms_totals_msisdn';
export const SMS_BROADCAST_SMS_CONTENT_BROADCAST = '/sms_broadcast/sms_content_broadcast';
export const SMS_BROADCAST_MSISDN_HISTORY = '/sms_broadcast/sms_broadcast_msisdn_history';
