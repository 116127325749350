import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
  DIALOG_CANCEL_MESSAGE,
  DIALOG_ICON,
  DIALOG_SUCCESS_MESSAGE,
  DIALOG_TYPE,
} from './../../../core/constants/dialog-type';
import { DialogConfig } from './dialog.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  dialogType: string;
  option: DialogConfig;
  title: string = null;
  msg: string = null;
  btnSuccessMsg = null;
  btnCancelMsg = null;
  msgAlign = 'center';
  icon = null;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private render: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.option = this.data.option as DialogConfig;
    this.title = this.data.title;
    this.dialogType = this.data.type;
    this.msg = this.data.msg;
    this.msgAlign = this.data.msgAlign || this.msgAlign;
    this.icon = this.data.icon;

    switch (this.dialogType) {
      case DIALOG_TYPE.ALERT:
        this.btnSuccessMsg = this.option.btnSuccessMessages || DIALOG_SUCCESS_MESSAGE.ALERT;
        this.btnCancelMsg = this.option.btnCancelMessages || DIALOG_CANCEL_MESSAGE.ALERT;
        this.icon = this.option.icon || DIALOG_ICON.ALERT;
        break;
      case DIALOG_TYPE.CONFIRM:
        this.btnSuccessMsg = this.option.btnSuccessMessages || DIALOG_SUCCESS_MESSAGE.CONFIRM;
        this.btnCancelMsg = this.option.btnCancelMessages || DIALOG_CANCEL_MESSAGE.CONFIRM;
        this.icon = this.option.icon || DIALOG_ICON.CONFIRM;
        break;
      case DIALOG_TYPE.ERROR:
        this.btnSuccessMsg = this.option.btnSuccessMessages || DIALOG_SUCCESS_MESSAGE.ERROR;
        this.btnCancelMsg = this.option.btnCancelMessages || DIALOG_CANCEL_MESSAGE.ERROR;
        this.icon = this.option.icon || DIALOG_ICON.ERROR;
        break;
      case DIALOG_TYPE.NOTIFY:
        this.btnSuccessMsg = this.option.btnSuccessMessages || DIALOG_SUCCESS_MESSAGE.NOTIFY;
        this.btnCancelMsg = this.option.btnCancelMessages || DIALOG_CANCEL_MESSAGE.NOTIFY;
        this.icon = this.option.icon || DIALOG_ICON.NOTIFY;
        break;
      case DIALOG_TYPE.WARN:
        this.btnSuccessMsg = this.option.btnSuccessMessages || DIALOG_SUCCESS_MESSAGE.WARN;
        this.btnCancelMsg = this.option.btnCancelMessages || DIALOG_CANCEL_MESSAGE.WARN;
        this.icon = this.option.icon || DIALOG_ICON.WARN;
        break;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
