import {
  SVG_CANCELREPORT,
  SVG_CLOUD_COMPUTING,
  SVG_DASHBOARD,
  SVG_DOWNLOAD,
  SVG_EXCEL,
  SVG_IMAGE,
  SVG_LINK,
  SVG_MVASREPORT,
  SVG_PDF,
  SVG_SEARCH_DOC,

  SVG_MENU_ACCOUNT_MANAGEMENT,
  SVG_MENU_MEDIA_PARTNER,
  SVG_MENU_SERVICE_MANAGEMENT,
  SVG_MENU_BLOCK_LIST_MANAGEMENT,
  SVG_MENU_WHITE_LIST_MANAGEMENT,
  SVG_MENU_CONTENT_MANAGEMENT,
  SVG_MENU_FREE_SERVICE_CONFIGURATION,
  SVG_MENU_PROFILE,
  SVG_MENU_REPORT,
  SVG_MENU_CUSTOMER,
  SVG_MENU_CONTENT,
  SVG_MENU_SHORT_CODE,
  SVG_MENU_FIND,
  SVG_MENU_DOT,
  SVG_NO_DATA_FOUND
} from 'src/app/core/constants/assets';

export const registerSVG = (domSanitizer, matIconRegistry) => {
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'dashboard', SVG_DASHBOARD );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'dashboard', SVG_DASHBOARD );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'mvas_report', SVG_MVASREPORT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'cancel_report', SVG_CANCELREPORT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'export-excel', SVG_EXCEL );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'export-image', SVG_IMAGE );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'export-pdf', SVG_PDF );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'cloud-computing', SVG_CLOUD_COMPUTING );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'download', SVG_DOWNLOAD );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'link', SVG_LINK );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'search-doc', SVG_SEARCH_DOC );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'nodata', SVG_NO_DATA_FOUND );

  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'account', SVG_MENU_ACCOUNT_MANAGEMENT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'media', SVG_MENU_MEDIA_PARTNER );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'service', SVG_MENU_SERVICE_MANAGEMENT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'block', SVG_MENU_BLOCK_LIST_MANAGEMENT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'white', SVG_MENU_WHITE_LIST_MANAGEMENT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'content', SVG_MENU_CONTENT_MANAGEMENT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'free_service', SVG_MENU_FREE_SERVICE_CONFIGURATION );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'profile', SVG_MENU_PROFILE );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'report', SVG_MENU_REPORT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'customer', SVG_MENU_CUSTOMER );

  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'upload', SVG_MENU_CONTENT );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'short_code', SVG_MENU_SHORT_CODE );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'find', SVG_MENU_FIND );
  regisSVGtoMatIcon(domSanitizer, matIconRegistry, 'dot', SVG_MENU_DOT );

};

export const regisSVGtoMatIcon = (domSanitizer, matIconRegistry, name, url) => {
  matIconRegistry.addSvgIcon(
    name,
    domSanitizer.bypassSecurityTrustResourceUrl(url)
  );
};
