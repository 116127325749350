import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { url } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { setTimeout } from 'timers';

import { AuthService } from '../../core/services/auth.service';
import { RouteInfo, User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      // const allowedRoles: Array<string> = next.data?.allowedRoles || [];
      return this.authService.isAuthenticated.pipe(
        mergeMap(isAuth => this.authService.currentUser.pipe(
          filter(user => user?.email !== null),
          map((user: User) => {
            console.log('[isAuth]', isAuth);
            console.log('!user?.route', !user?.route);
            console.log('user?.route && !isRouteAllowed(state, user', user?.route && !isRouteAllowed(state, user));
            if (!isAuth) {
              this.router.navigate(['/auth/login']);
              return false;
            } else if (!user?.route) {
              const decodeUrl = decodeURIComponent(state.url).split('?');
              this.router.navigate([decodeUrl[0]], { queryParams: decodeUrl.length > 1 ? getParams(state.url) : ''});
              return false;
            } else if (user?.route && !isRouteAllowed(state, user)) {
              console.log('[user.role]', user.role);
              console.log('[user?.route]', user?.route);
              // login but no permission
              console.log('[user.role]', user.role);
              if (user.role === 'callcenter') {
                this.router.navigate(['/customer_service']);
              } else if (user.role === 'co') {
                this.router.navigate(['/upload_content']);
              } else if (user.role === 'cfo_admin') {
                this.router.navigate(['/reports/financial']);
              } else if (user.role === 'vote_admin') {
                this.router.navigate(['/reports/vote_report']);
              } else if (user.role === 'operator') {
                this.router.navigate(['/reports/subscriber_report']);
              } else {
                this.router.navigate(['/dashboard']);
              }
              return false;
            }
            return true;
          })
        ))
      );
    }

}

function getParams(str) {
  let queryString = str || window.location.search || '';
  let keyValPairs = [];
  const params = {};
  queryString = queryString.replace(/.*?\?/, '');

  if (queryString.length) {
    keyValPairs = queryString.split('&');
    // tslint:disable-next-line:forin
    for (const pairNum in keyValPairs) {
      const key = keyValPairs[pairNum].split('=')[0];
      if (!key.length) { continue; }
      if (typeof params[key] === 'undefined') {
        params[key] = [];
      }
      params[key].push(keyValPairs[pairNum].split('=')[1]);
    }
  }
  return params;
}

function isRouteAllowed(state: RouterStateSnapshot, user: User) {
  const routes: RouteInfo[] = user.route;
  let isAllowed;
  const flattenedRoutes = flatRoutePath(routes);
  const requestUrl = state.url.slice(0, 1) === '/' ? state.url.slice(1) : state.url;
  if (user.role === 'vote_admin' && requestUrl === 'dashboard') {
    return false;
  } else if (user.role === 'vote_admin' ){
    isAllowed = flattenedRoutes.every(route => requestUrl.includes(route.route));
  } else {
    isAllowed = flattenedRoutes.some(route => route.route !== '' && requestUrl.includes(route.route));
  }

  if ( isAllowed ) {
    return true;
  }
  return false;
}

function flatRoutePath(routes: RouteInfo[], prefix = ''): RouteInfo[] {
  return routes.reduce((flattened, r) => {
    const newRoute = prefix + r.route;

    return flattened
      .concat([{...r, route: newRoute}])
      .concat(r.children ? flatRoutePath(r.children, newRoute) : []);
  }, []);
}
