import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { User } from 'src/app/core/models/user';

import { ACCESS_TOKEN_KEY, USER_INFO_KEY } from '../constants/localstorage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  private readonly accessTokenKey = this.softEncode(ACCESS_TOKEN_KEY);
  private readonly userInfoKey = this.softEncode(USER_INFO_KEY);

  getToken(): string {
    return window.localStorage.getItem(this.accessTokenKey);
  }

  saveToken(token: string) {
    window.localStorage.setItem(this.accessTokenKey, token);
  }

  destroyToken() {
    window.localStorage.removeItem(this.accessTokenKey);
  }

  getUser(): User {
    if (
      window.localStorage.getItem(this.userInfoKey) === null ||
      window.localStorage.getItem(this.userInfoKey) === 'null' ||
      window.localStorage.getItem(this.userInfoKey) === ''
    ) {
      return new User();
    } else {
      return new User(JSON.parse(this.softDecode(window.localStorage.getItem(this.userInfoKey))));
    }
  }

  saveUser(user: User) {
    window.localStorage.setItem(this.userInfoKey, this.softEncode(JSON.stringify(user)));
  }

  destroyUser() {
    window.localStorage.removeItem(this.userInfoKey);
  }

  destroyAll() {
    window.localStorage.removeItem(this.userInfoKey);
    window.localStorage.removeItem(this.accessTokenKey);
  }

  private softEncode(str: string) {
    return btoa(encodeURI(str));
  }

  private softDecode(str: string) {
    return decodeURI(atob(str));
  }

  // unused
  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch (Error){
        return null;
    }
  }

}
