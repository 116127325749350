
<div class="flex-container">

  <mat-toolbar
    [ngClass]="{opened: (layoutsService.isNavbarOpen | async)}"
    dark>

    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="layoutsService.toggleSidebar()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>

    <img class="brandlogo" [src]="brandlogo" aria-label="MVAS">

    <span class="spacer"></span>

    <div *ngIf="!isLoggedIn">
      <button
        type="button"
        aria-label="Login"
        mat-icon-button
        routerLink="/auth/login">
        Login
      </button>
    </div>

    <div *ngIf="isLoggedIn">

      <button mat-button [matMenuTriggerFor]="loginMenu">
          <app-user-icon [user]="user"></app-user-icon>
          <span [hidden]="!breakpoint" style="font-weight: normal;">{{email}}</span>
          <mat-icon aria-hidden="false" aria-label="More">expand_more</mat-icon>
      </button>

      <mat-menu #loginMenu="matMenu" xPosition="before">
        <!-- Disable via register with account management page -->
        <!-- <button
          *ngIf="user.role === 'admin'"
          mat-menu-item
          color="secondary"
          type="button"
          aria-label="Register"
          (click)="doRegister()">
          Register new user
        </button> -->
        <button
          mat-menu-item
          color="secondary"
          type="button"
          aria-label="Profile"
          (click)="onProfile()">
          Profile
        </button>
        <button
          mat-menu-item
          color="secondary"
          type="button"
          aria-label="Logout"
          (click)="doLogout()">
          Logout
        </button>
      </mat-menu>


    </div>

  </mat-toolbar>


  <mat-sidenav-container>


    <mat-sidenav #drawer
      mode="side"
      [opened]="(layoutsService.isSidenavOpen | async)"
     >
      <mat-nav-list *ngFor="let link of links">
        <!-- temporary use "dashboard" as an icon -->
        <a class="nav-text" mat-list-item [routerLink]="link.routerLink" *ngIf="!link.children && link.isShow" routerLinkActive="is-active">
          
          <mat-icon *ngIf="link.icon" [svgIcon]="link.icon"></mat-icon>
          <div>
            {{ link.title }}
          </div>
        </a>

        <!-- accordion -->
        <a class="nav-text accordion-menu" mat-list-item *ngIf="link.children && link.isShow" (click)="onAccordionClick(link)">
          <mat-icon *ngIf="link.icon" [svgIcon]="link.icon"></mat-icon>
          <div>
            {{ link.title }}
            <mat-icon class="caret" aria-hidden="false" aria-label="More">expand_more</mat-icon>
          </div>
        </a>

        <div class="accordion-children" [ngClass]="{'hidden': !link.opened}">
          <a class="nav-text" mat-list-item [routerLink]="child.routerLink" *ngFor="let child of link.children" routerLinkActive="is-active">
            <mat-icon class="icon-child" *ngIf="child.icon" [svgIcon]="child.icon"></mat-icon>
            {{ child.title }}
          </a>
        </div>
      </mat-nav-list>
      <div class="version">MVAS Performance Report Platform<br /> Version v.{{version}}</div>
    </mat-sidenav>


    <mat-sidenav-content #sidenavcontent>
      <router-outlet (activate)=onRouteChange($event)></router-outlet>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
