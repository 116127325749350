import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent implements OnInit {

  @Input() user: User;

  constructor( ) {}

  letter: string;

  ngOnInit() {
    const email = this.user?.email;
    if (email) {
      this.letter = this.user?.email.slice(0, 1).toUpperCase();
    }

  }

}
