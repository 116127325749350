import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { UserCP, UserInfoList, UserProfile, UserSpacial } from '../interfaces/user.interface';
import {
  AUTH_REGISTER_ROUTE,
  CP_ROUTE,
  OPERATOR_ROUTE,
  ROLE_ROUTE,
  SERVICE_OPERATOR_ROUTE,
  USERS_CHANGE_PASSWORD,
  USERS_CO_ROUTE,
  USERS_MP_ROUTE,
  USERS_PROFILE_ROUTE,
  USERS_ROUTE,
  USERS_SERVICE_ROUTE,
  USER_CP_ROUTE,
} from './../constants/api';
import { UserContentOwner, UserMediaPartner, UserOperator, UserServiceInfo } from './../interfaces/user.interface';
import { RoleInfoList } from './../models/user';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

constructor(
  private apiService: ApiService,
) { }

cacheRoles: Observable<RoleInfoList>;
cacheOperators: Observable<UserOperator[]>;
cacheMediaPartners: Observable<UserMediaPartner[]>;
cacheProfile: Observable<UserProfile>;
cacheCP: Observable<UserCP>;
cacheServiceOperator: Observable<any>;
cacheUserCP: Observable<any>;

getUsers(params?: { type?: string }): Observable<UserInfoList> {
  if ( params?.type ) {
    return this.apiService.get(USERS_ROUTE, new HttpParams({ fromObject: params }));
  }
  return this.apiService.get(USERS_ROUTE);
}

getMediaPartners(): Observable<UserMediaPartner[]> {
  if ( !this.cacheMediaPartners ) { this.cacheMediaPartners = this.apiService.get(USERS_MP_ROUTE).pipe(shareReplay()); }
  return this.cacheMediaPartners;
}

getUserMediaPartner(): Observable<UserMediaPartner[]> {
  return this.apiService.get(USERS_MP_ROUTE);
}

getContentOwners(): Observable<UserContentOwner[]> {
  return this.apiService.get(USERS_CO_ROUTE);
}

getProfile(userId?: string): Observable<UserProfile> {
  this.cacheProfile = this.apiService.get(USERS_PROFILE_ROUTE, userId ? new HttpParams({ fromObject: { userId } })
   : undefined).pipe(shareReplay());
  return this.cacheProfile;
}

getRoles(): Observable<RoleInfoList>{
  if ( !this.cacheRoles ) { this.cacheRoles = this.apiService.get(ROLE_ROUTE).pipe(shareReplay()); }
  return this.cacheRoles;
}

getOperators(): Observable<UserOperator[]> {
  if ( !this.cacheOperators ) { this.cacheOperators = this.apiService.get(OPERATOR_ROUTE).pipe(shareReplay()); }
  return this.cacheOperators;
}

getUserCP(): Observable<any[]> {
  if ( !this.cacheUserCP ) { this.cacheUserCP = this.apiService.get(USER_CP_ROUTE).pipe(shareReplay()); }
  return this.cacheUserCP;
}

getUserServices(): Observable<UserServiceInfo[]> {
  return this.apiService.get(USERS_SERVICE_ROUTE);
}

// getServiceOperator(): Observable<any[]> {
//   if (!this.cacheServiceOperator) { this.cacheServiceOperator = this.apiService.get(SERVICE_OPERATOR_ROUTE).pipe(shareReplay()); }
//   return this.cacheServiceOperator;
// }

getServiceOperator(filter?: { operId?: string; mpId?: string }): Observable<any> {
  return this.apiService.get(
    SERVICE_OPERATOR_ROUTE,
    new HttpParams({ fromObject: filter })
  );
}

getCP(): Observable<UserCP> {
  if (!this.cacheCP ) { this.cacheCP = this.apiService.get(CP_ROUTE).pipe(shareReplay()); }
  return this.cacheCP;
}

createUser(userProfile: UserProfile): Observable<void> {
  return this.apiService.post(AUTH_REGISTER_ROUTE, userProfile);
}

changePassword(params: {oldPassword: string, newPassword: string, confirmPassword: string}): Observable<void> {
  return this.apiService.post(USERS_CHANGE_PASSWORD, params);
}

}
