import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

export const IsNullOrUndefinedOrEmpty = (value: any): boolean => {
  return value === null || value === undefined || value.length === 0;
};

export const IsNullOrUndefined = (value: any): boolean => {
  return value === null || value === undefined;
};

export class Utils {
  static MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static passwordStrengthValidator(value: string, useCase: boolean = false) {
    {
      const caseMust = [];
      if (!value) {
        return null;
      }
      const upperCaseCharacters = /[A-Z]+/g;
      if (upperCaseCharacters.test(value) === false) {
        return {
          passwordStrength: `*รหัสผ่านต้องประกอบด้วยตัวพิมพ์ใหญ่อย่างน้อย 1ตัว`,
          passwordStrengthEn: `*At least 1 upper case letter (A-Z)`,
          caseMust,
        };
      } else {
        caseMust.push({ upperCase: true });
      }

      const lowerCaseCharacters = /[a-z]+/g;
      if (lowerCaseCharacters.test(value) === false) {
        return {
          passwordStrength: ` *รหัสผ่านต้องประกอบด้วยตัวพิมพ์เล็กอย่างน้อย 1ตัว`,
          passwordStrengthEn: `*At least 1 lower case letter (a-z)`,
          caseMust,
        };
      } else {
        caseMust.push({ lowerCase: true });
      }

      const numberCharacters = /[0-9]+/g;
      if (numberCharacters.test(value) === false) {
        return {
          passwordStrength: `*รหัสผ่านต้องประกอบด้วยตัวเลขอย่างน้อย 1ตัว`,
          passwordStrengthEn: `*At least 1 number (0-9)`,
          caseMust,
        };
      } else {
        caseMust.push({ numberCase: true });
      }

      const specialCharacters = /.{8,}$/;
      if (specialCharacters.test(value) === false) {
        return {
          passwordStrength: `*รหัสผ่านต้องรวมกันไม่ต่ำกว่า 8 ตัว`,
          passwordStrengthEn: `*At least 8 characters`,
          caseMust,
        };
      } else {
        caseMust.push({ specialCase: true });
      }

      if (useCase) {
        return {
          passwordStrength: '',
          caseMust,
        };
      } else {
        return null;
      }
    }
  }

  static toFormData(
    file: any,
    model: any,
    fieldFileName: string,
    ignoreField: string[] = []
  ) {
    const formData = new FormData();
    const newModel = model;
    newModel[fieldFileName] = file;
    for (const key in newModel) {
      if (newModel.hasOwnProperty(key)) {
        if (ignoreField.indexOf(key) < 0) {
          formData.append(`${key}`, newModel[`${key}`]);
        }
      }
    }
    return formData;
  }

  static mapArrayToMatSelectGroup(dataSource: any[], keyStr: string): any[] {
    const groupBy = (objectArray: any[], property: string) => {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    };

    const groupedPeople = groupBy(dataSource, keyStr);

    const newArrayValue = [];
    Object.keys(groupedPeople).forEach((item: any) => {
      console.log(item); // key
      const newObj = {};
      // newObj.name = item;
      // newObj.info = groupedPeople[item];
      newArrayValue.push(newObj);
    });

    return newArrayValue;
  }
}

export const SearchFilterOrSort = (
  dataSource?: any[],
  filter?: string,
  sortOrder?: string,
  pageNumber?: number,
  pageSize?: number,
  key?: string
) => {
  // tslint:disable-next-line: variable-name
  const _filter = filter || '';
  // tslint:disable-next-line: variable-name
  const _sortOrder = sortOrder;
  // tslint:disable-next-line: variable-name
  const _pageNumber = pageNumber;
  // tslint:disable-next-line: variable-name
  const _pageSize = pageSize;

  let data = dataSource.sort((r1, r2) => r1.idx - r2.idx);

  if (_filter) {
    data = data.filter(
      (revenue) =>
        revenue[key].trim().toLowerCase().search(_filter.toLowerCase()) >= 0
    );
  }

  if (_sortOrder === 'desc') {
    data = data.reverse();
  }

  const initialPos = _pageNumber * _pageSize;

  const dataPage = data.slice(initialPos, initialPos + _pageSize);

  return dataPage;
};

export const DateRangesSelector = () => {
  return {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'This Year': [moment().startOf('year'), moment().endOf('year')],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
  };
};

export class SummaryTable extends Array {
  sum(key: string) {
    return this.reduce((a, b) => a + (b[key] || 0), 0);
  }
}
