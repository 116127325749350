import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Observable } from 'rxjs';

import { DIALOG_TYPE } from '../../../core/constants/dialog-type';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  error(title: string, msg: string, opts?: DialogConfig) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...opts };
    return this.showDialog(title, msg, DIALOG_TYPE.ERROR, dialogConfig);
  }

  warn(title: string, msg: string, opts?: DialogConfig) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...opts };
    return this.showDialog(title, msg, DIALOG_TYPE.WARN, dialogConfig);
  }

  notify(title: string, msg: string, opts?: DialogConfig) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...opts };
    return this.showDialog(title, msg, DIALOG_TYPE.NOTIFY, dialogConfig);
  }

  confirm(title: string, msg: string, opts?: DialogConfig) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...opts };
    return this.showDialog(title, msg, DIALOG_TYPE.CONFIRM, dialogConfig);
  }

  alert(title: string, msg: string, opts?: DialogConfig) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...opts };
    return this.showDialog(title, msg, DIALOG_TYPE.ALERT, dialogConfig);
  }

  showCustomDialog<T>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    opt?: DialogConfig
  ): Observable<any> {
    const dialogConfig = { ...DEFAULT_CONFIG, ...opt };

    return this.showDialog(
      '',
      null,
      null,
      dialogConfig,
      componentOrTemplateRef
    );
  }

  showDialog(
    title?: string,
    msg?: string,
    type?: string,
    opts?: DialogConfig,
    dialogComponent: any = DialogComponent
  ): Observable<any> {
    if (opts && opts.singleInstant) {
      const dialogComponentOpen = this.dialog.openDialogs.find((instance) => {
        return (
          instance.componentInstance.constructor.name === 'DialogComponent'
        );
      });
      if (dialogComponentOpen) {
        return EMPTY;
      }
    }
    const dialog = this.dialog
      .open(dialogComponent, {
        width: opts.width,
        data: {
          title,
          msg,
          type,
          option: opts,
        },
      })
      .afterClosed();

    return dialog;
  }
}

const DEFAULT_CONFIG: DialogConfig = {
  backdrop: true,
  backdropClass: 'dark-backdrop',
  animation: true,
  btnSuccessMessages: null,
  btnCancelMessages: null,
  windowClass: '',
  singleInstant: false,
  width: '300px',
  msgAlign: 'center',
};

export interface DialogConfig {
  panelClass?: string;
  // Set to false to disable animations on new modal/backdrop. Does not toggle animations for modals/backdrops that are already displayed.
  animation?: boolean;
  // Backdrop class
  backdrop?: boolean | string;
  // additional CSS class(es) to be added to a modal backdrop template
  backdropClass?: string;
  // additional CSS class(es) to be added to a modal window template
  windowClass?: string;
  // Message alignment
  msgAlign?: string;
  // Big Icon on modal. Should be material icon name.
  icon?: string;
  // Optional size of modal.
  width?: string;
  height?: string;
  // Optional map object redefining the text of a dialog button.
  btnSuccessMessages?: string;
  btnCancelMessages?: string;
  // Keep tracking of single dialog.
  singleInstant?: boolean;
  parameter?: any;
  
}
