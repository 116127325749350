import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, retry, tap } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';

import { AUTH_FORGOT_ROUTE, AUTH_LOGIN_ROUTE, AUTH_RESET_PASSWORD, AUTH_VERIFY_TOKEN, USERS_PROFILE_ROUTE } from '../constants/api';
import { APIResponse } from '../interfaces/response.interface';
import { DialogService } from './../../shared/modules/dialog/dialog.service';
import { UserLoginInfo } from './../interfaces/user.interface';
import { ApiService } from './api.service';
import { LocalStorageService } from './localstorage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  // this runs once on application startup.
  populate() {
    if (this.localStorageService.getToken()) {
      // request once to ensure the token is valid
      this.apiService.get(USERS_PROFILE_ROUTE).pipe(retry(3)).subscribe(
        userdata => {
          // restore user from localStorage
          this.isAuthenticatedSubject.next(true);
          this.currentUserSubject.next(this.localStorageService.getUser());
        },
        err => {
          this.purgeAuth();
          this.dialogService.error('Error', 'Your session is timeout, Please login again.');
        }
      );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    this.localStorageService.saveToken(user.accessToken);
    this.localStorageService.saveUser(user);
    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    this.localStorageService.destroyAll();
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
  }

  attemptAuth(type: 'login' | '', credentials: UserLoginInfo): Observable<User> {
    let route;
    switch (type) {
      case 'login':
        route = AUTH_LOGIN_ROUTE;
        break;
      }
    return this.apiService.post(route, credentials).pipe(
      map(userdata => {
        const user = new User(userdata);
        this.setAuth(user);
        return userdata;
      })
    );
  }

  forgotPassword(email: string): Observable<APIResponse> {
    return this.apiService.postAuth(AUTH_FORGOT_ROUTE, { email }).pipe(tap(v => console.log(v)));
  }

  verifyToken(token: string): Observable<APIResponse> {
    return this.apiService.postAuth(AUTH_VERIFY_TOKEN, {
      resetPasswordToken: token
    });
  }

  resetPassword(password: string, token: string): Observable<APIResponse> {
    return this.apiService.postAuth(AUTH_RESET_PASSWORD, {
      password,
      resetPasswordToken: token
    });
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }
}
