import { UserIconComponent } from './../shared/components/user-icon/user-icon.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { DefaultLayoutsComponent } from './default-layouts/default-layouts.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [AuthLayoutComponent, DefaultLayoutsComponent, UserIconComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
  ]
})
export class LayoutsModule { }
