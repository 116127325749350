import { RoleInfo as IRoleInfo } from 'src/app/core/interfaces/user.interface';

import { RouteInfo as IRouteInfo } from './../interfaces/user.interface';
import { Navlink } from './navlink';

// this class represents the user information in rhe session
// will be save in user stores and present in runtime session
export class User {
  id: string = null;
  email: string = null;
  // password: string = null;
  name: string = null;
  role: string = null;
  expire: string = null;
  isConfirm = false;
  accessToken: string = null;
  username: string = null;
  status = -1; // TODO: define what this is
  route: RouteInfo[] = [];

  constructor(userData?: any) {
    this.id = userData?.id || userData?._id || null;
    this.email = userData?.email || null;
    // this.password = userData?.password || null;
    this.name = userData?.firstName || null;
    this.role = userData?.role || null;
    this.accessToken = userData?.token || null;
    this.expire = userData?.expire || null;
    this.isConfirm = userData?.isConfirm || false;
    this.accessToken = userData?.accessToken || null;
    this.username = userData?.username || null;
    this.status = userData?.status || -1;
    this.route = userData?.route || [];
  }
}

export class RouteInfo implements IRouteInfo {
  displayName: string = null;
  iconName: string = null;
  expectedRole: string = null;
  route: string = null;
  isShow = false;
  class: string = null;
  children: RouteInfo[];

  convertToNavlink = (): Navlink => {
    const navlink = {
      title: this.displayName,
      expectedRole: this. expectedRole,
      icon: this.iconName,
      isShow: this.isShow,
      routerLink: this.route.slice(0, 1) === '/' ? this.route : '/' + this.route,
      children: this.children?.map(route => Object.assign(new RouteInfo(), route).convertToNavlink()) || null,
    } as Navlink;
    return navlink;
  }
}

export class RoleInfoList extends Array<IRoleInfo> {
  roleIdToName = (roleId: string) => {
    return this.find(r => r.roleId === roleId);
  }
}
