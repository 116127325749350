import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user';

import { BRANDLOGO } from './../../core/constants/assets';
import { LINKS } from './../../core/constants/links';
import { Navlink } from './../../core/models/navlink';
import { RouteInfo } from './../../core/models/user';
import { AuthService } from './../../core/services/auth.service';
import { LayoutsService } from './../../core/services/layouts.service';

import { version } from '../../../../package.json';
import { UserService } from 'src/app/core/services/user.service';
import { IsNullOrUndefinedOrEmpty } from 'src/app/shared/helper/utils';

@Component({
  selector: 'app-default-layouts',
  templateUrl: './default-layouts.component.html',
  styleUrls: ['./default-layouts.component.scss']
})
export class DefaultLayoutsComponent implements OnInit, OnDestroy {

  @ViewChild('sidenavcontent') sidenavContent;

  public version: string = version;
  public breakpoint;
  constructor(
    private authService: AuthService,
    private router: Router,
    public layoutsService: LayoutsService,
    private userService: UserService
  ) { }
  subscriptions: Subscription[] = [];

  brandlogo = '';

  user: User = {} as User;
  isLoggedIn: boolean;
  email: string;

links: Array<Navlink> = LINKS;

  ngOnInit() {
    this.subscriptions.push(this.authService.isAuthenticated.subscribe(isAuth => this.isLoggedIn = isAuth));
    this.subscriptions.push(this.authService.currentUser.subscribe((user: User) => {
      this.email = user?.email;
      this.user = user;

      this.links = user.route?.map((route: RouteInfo) => Object.assign(new RouteInfo(), route).convertToNavlink());
    }));
    this.subscriptions.push(
      this.userService.getProfile().subscribe(v => {
        if (!IsNullOrUndefinedOrEmpty(v.logoUrl) && !(/missing/).test(v.logoUrl)) {
          this.brandlogo = v.logoUrl;
        } else {
          this.brandlogo = BRANDLOGO;
        }
      })
    );

    this.breakpoint = (window.innerWidth <= 600) ? this.layoutsService.toggleSidebar() : true;

    if (this.links.length === 1) {
      this.layoutsService.closeSidebar();
    } else {
      this.layoutsService.openSidebar();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  doLogout() {
    this.authService.purgeAuth();
    this.router.navigate(['/auth/login']);
  }

  onProfile() {
    this.router.navigate(['/profile']);
  }

  doRegister() {
    this.router.navigate(['/auth/register']);
  }

  onRouteChange(event) {
    this.sidenavContent?.elementRef.nativeElement.scrollTo(0, 0);
  }

  onAccordionClick(link: Navlink) {
    this.links.filter(l => l.title !== link.title).forEach(l => l.opened = false);
    link.opened = !link.opened;
  }
}
