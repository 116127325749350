import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import * as moment from 'moment';

import { AuthService } from './core/services/auth.service';
import { registerSVG } from './shared/helper/svg-register';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  viewProviders: [MatIconRegistry],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router, // for debug in augury
    private authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translocoService: TranslocoService
  ) {
    // register custom svgs icon
    registerSVG(domSanitizer, matIconRegistry);

    // register default lang
    moment.locale(this.translocoService.getActiveLang());
  }

  ngOnInit() {
    // restore login credentials
    this.authService.populate();
  }
}
