<h2 mat-dialog-title align="center">{{title}}</h2>
<mat-dialog-content class="mat-typography">
  <p [style.textAlign]="'center'">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>
  </p>
  <p align="{{msgAlign}}">
    {{msg}}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button *ngIf="btnCancelMsg" mat-dialog-close>{{btnCancelMsg}}</button>
  <button mat-button color="primary" *ngIf="btnSuccessMsg" [mat-dialog-close]="true" cdkFocusInitial>{{btnSuccessMsg}}</button>
</mat-dialog-actions>
