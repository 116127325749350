import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { IsNullOrUndefinedOrEmpty } from 'src/app/shared/helper/utils';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/localstorage.service';
import { CONTENT_IMPORT_FILE_ROUTE, CONTENT_CATEGORY_ROUTE } from './../constants/api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private jwtService: LocalStorageService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  private AUTH_HEADER = 'Authorization';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private readonly isRefreshTokenEnabled: false;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type') && !(req.body instanceof FormData) ) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
      });
    }

    req = this.addAuthenticationToken(req);

    const data = {
      headers: req.headers,
      body: req.body,
      parameter: req.params.keys(),
    };

    if (!environment.production) {
      // console.log(
      //   `%c==== HttpRequesting [${req.method}]:[${req.url}] Parameter:=> ====`,
      //   'background: #00E6C3; color: #000000',
      //   data
      // );
    }

    // excludes spinner path
    if (!(req.url.includes(CONTENT_IMPORT_FILE_ROUTE) && req.method === 'POST') ) {
      this.spinner.show();
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinner.hide();
          if (!environment.production) {
            // console.log(
            //   `%c==== HttpResponse [${req.method}]:[${req.url}] Parameter:=> ====`,
            //   'background: #FF8900; color: #000000',
            //   event.body
            // );
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error && (error.status === 401 || error.status === 419)) {
          // 401 and 403 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.isRefreshTokenEnabled) {
            if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // which means the new token is ready and we can retry the request again

              // In case can't refresh token because refresh token is expired
              if (error.url.match(/refresh_token/g)) {
                this.authService.purgeAuth();
                this.router.navigate(['/auth/login']);
                return throwError(error);
              }

              return this.refreshTokenSubject.pipe(
                filter((success) => success !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(req)))
              );
            } else {
              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);

              // return this.authService.refreshToken().pipe(
              //   switchMap((success: boolean) => {
              //     this.refreshTokenSubject.next(success);
              //     return next.handle(this.addAuthenticationToken(req));
              //   }),
              //   // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              //   // for the next time the token needs to be refreshed
              //   finalize(() => (this.refreshTokenInProgress = false))
              // );
            }
          } else {
            // kick user out because session expired
            this.authService.purgeAuth();
            this.router.navigate(['/auth/login']);
            return throwError(error);
          }
        } else {
          return throwError(error);
        }
      })
    ) as Observable<HttpEvent<any>>;
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.jwtService.getToken();
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!token) {
      return request;
    }
    // TODO: If you are calling an outside domain then do not add the token.
    // if (!request.url.match(/www.mydomain.com\//)) {
    //   return request;
    // }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + token),
    });
  }
}
