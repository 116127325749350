import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutsService {

  private isSidenavOpenSubject = new BehaviorSubject<boolean> (true);
  public isSidenavOpen = this.isSidenavOpenSubject.asObservable();

  private isNavbarOpenSubject = new BehaviorSubject<boolean> (true);
  public isNavbarOpen = this.isNavbarOpenSubject.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) { }

  // TODO : responsive breakpoint
  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
  //   map(result => result.matches),
  //   shareReplay()
  // );

  public openSidebar() { this.isSidenavOpenSubject.next(true); }
  public closeSidebar(options = {}) {
    this.isSidenavOpenSubject.next(false);
  }

  public toggleSidebar() { this.isSidenavOpenSubject.next(!this.isSidenavOpenSubject.getValue()); }

  public openNavbar() { this.isSidenavOpenSubject.next(true); }
  public closeNavbar(options = {}) {
    this.isSidenavOpenSubject.next(false);
  }

  public toggleNavbar() { this.isSidenavOpenSubject.next(!this.isSidenavOpenSubject.getValue()); }
}
