import { Navlink } from './../models/navlink';

// TODO : store links in better places
export const LINKS: Array<Navlink> = [
  {
    title: 'Dashboard',
    routerLink: '/dashboard',
    icon: 'dashboard'
  },
  {
    title: 'MVAS Report',
    routerLink: '/report/mvas',
    icon: 'mvas_report'
  },
  {
    title: 'Cancel Report',
    icon: 'cancel_report',
    children: [
      {
        title: 'Cancel Report',
        routerLink: '/report/cancel/summary'
      },
      {
        title: 'Top Cancel',
        routerLink: '/report/cancel/top'
      },
    ]
  },
  {
    title: 'Account Management',
    routerLink: '/account',
    icon: 'mvas_report'
  },
];
