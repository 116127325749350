export const DIALOG_TYPE = {
  CONFIRM : 'CONFIRM',
  ALERT : 'ALERT',
  WARN : 'WARN',
  ERROR : 'ERROR',
  NOTIFY : 'NOTIFY'
};

export const DIALOG_SUCCESS_MESSAGE = {
  CONFIRM : 'Yes',
  ALERT : 'Yes',
  ERROR : null,
  WARN : 'Ok',
  NOTIFY : null
};

export const DIALOG_CANCEL_MESSAGE = {
  CONFIRM : 'No',
  ALERT : 'No',
  ERROR : 'Close',
  WARN : 'Cancel',
  NOTIFY : 'Close'
};

export const DIALOG_ICON = {
  CONFIRM : 'help_outline',
  ALERT : 'highlight_off',
  ERROR : 'error_outline',
  WARN : 'error_outline',
  NOTIFY : 'check_circle_outline'
};
