import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { DefaultLayoutsComponent } from './layouts/default-layouts/default-layouts.component';
import { PageNotFoundComponent } from './pages/not-found/not-found.component';

// https://stackblitz.com/edit/angular-login-hide-navbar-layout?file=src%2Fapp%2Fapp-routing.module.ts
// children route layouts

// menus - for ui menu (use route from api)
// route component - must have all routes defined
// dynamic enable - disable route by role (at guard and filter by api)
const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutsComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'reports',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/report/report.module').then((m) => m.ReportModule),
      },
      {
        // path: 'content',
        path: 'content_owner_management',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/content/content.module').then((m) => m.ContentModule),
      },
      {
        path: 'customer_service',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/customer/customer.module').then(
            (m) => m.CustomerModule
          ),
      },
      {
        // TODO: put this in sub-router instead of this
        path: 'blocklist/import',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/customer/customer-blocklist-import/customer-blocklist-import.module').then(
            (m) => m.CustomerBlocklistImportModule
          ),
      },
      {
        // TODO: put this in sub-router instead of this
        path: 'blocklist',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/customer/customer-blocklist-number/customer-blocklist-number.module').then(
            (m) => m.CustomerBlocklistNumberModule
          ),
      },
      {
        // TODO: put this in sub-router instead of this
        path: 'blacklist',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/customer/customer-blacklist-number/customer-blacklist-number.module').then(
            (m) => m.CustomerBlacklistNumberModule
          ),
      },
      {
        // TODO: put this in sub-router instead of this
        path: 'whitelist',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/customer/customer-whitelist-number/customer-whitelist-number.module').then(
            (m) => m.CustomerWhitelistNumberModule
          ),
      },
      {
        path: 'voice_approve',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/voice-approve/voice-approve.module').then(
            (m) => m.VoiceApproveModule
          ),
      },
      {
        path: 'sms_broadcast',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/sms-broadcast/sms-broadcast.module').then(
            (m) => m.SmsBroadcastModule
          ),
      },
      {
        // TODO: put this in sub-router instead of this
        path: 'cataloges',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/service/service-catalog/service-catalog.module').then((m) => m.ServiceCatalogModule),
      },
      {
        // TODO: put this in sub-router instead of this
        path: 'upload_content',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/content/content-upload/content-upload.module').then((m) => m.ContentUploadModule),
      },
      {
        path: 'service-management',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/service/service.module').then((m) => m.ServiceModule),
      },
      {
        path: 'free_service_config',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/free-service-config/free-service-config.module').then((m) => m.FreeServiceConfigModule),
      },
      {
        // path: 'partner',
        // path: 'content_provider_management',
        path: 'media_partner_management',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/partner/partner.module').then((m) => m.PartnerModule),
      },
      {
        // path: 'content',
        path: 'content_short_code_mapping_list',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/content/content-mapping/content-mapping.module').then((m) => m.ContentMappingModule),
      },
      {
        path: 'accounts',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/accounts/accounts.module').then((m) => m.AccountsModule),
      },
      {
        path: 'find_log',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/monitor/monitor.module').then((m) => m.MonitorModule),
      },
      {
        path: 'profile',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'content_provider_management',
        pathMatch: 'prefix',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/content-provider/content-provider.module').then((m) => m.ContentProviderModule),
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
