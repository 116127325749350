export const BRANDLOGO = '/assets/logo.svg';
export const BRANDLOGO_DARK = '/assets/logo_dark.svg';
export const BG_SIDE_LOGIN = '/assets/bg_side_login.jpg';

export const SVG_DASHBOARD = '/assets/icn_dashboard.svg';
export const SVG_MVASREPORT = 'assets/icn_mvasreport.svg';
export const SVG_CANCELREPORT = 'assets/icn_cancelreport.svg';
export const SVG_EXCEL = 'assets/icn_excel.svg';
export const SVG_IMAGE = 'assets/icn_image.svg';
export const SVG_PDF = 'assets/icn_pdf.svg';
export const SVG_CLOUD_COMPUTING = 'assets/icn_cloud_computing.svg';
export const SVG_SEARCH_DOC = 'assets/icn_search_doc.svg';
export const SVG_DOWNLOAD = 'assets/icn_downloadbutton.svg';
export const SVG_LINK = 'assets/icn_link.svg';
export const SVG_NO_DATA_FOUND = 'assets/nodata.svg';

export const SVG_MENU_DASHBOARD = '/assets/menu-icon/dashboard.svg';
export const SVG_MENU_ACCOUNT_MANAGEMENT = '/assets/menu-icon/account.svg';
export const SVG_MENU_MEDIA_PARTNER = '/assets/menu-icon/media-partner.svg';
export const SVG_MENU_SERVICE_MANAGEMENT = '/assets/menu-icon/service.svg';
export const SVG_MENU_BLOCK_LIST_MANAGEMENT = '/assets/menu-icon/block.svg';
export const SVG_MENU_WHITE_LIST_MANAGEMENT = '/assets/menu-icon/white.svg';
export const SVG_MENU_CONTENT_MANAGEMENT = '/assets/menu-icon/seek_active.svg';
export const SVG_MENU_FREE_SERVICE_CONFIGURATION = '/assets/menu-icon/free.svg';
export const SVG_MENU_PROFILE = '/assets/menu-icon/profile.svg';
export const SVG_MENU_REPORT = '/assets/menu-icon/report.svg';
export const SVG_MENU_CUSTOMER = '/assets/menu-icon/customer.svg';
export const SVG_MENU_CONTENT = '/assets/menu-icon/content.svg';
export const SVG_MENU_SHORT_CODE = '/assets/menu-icon/short-code.svg';
export const SVG_MENU_FIND = '/assets/menu-icon/find.svg';
export const SVG_MENU_DOT = '/assets/menu-icon/dot.svg';
