import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';

import { APIResponse } from '../interfaces/response.interface';
import { environment } from './../../../environments/environment';

const defaultOptions = {
  format: true
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
  ) {}

  private readonly apiUrl = this.normalizePath(environment.api_url);

  private normalizePath(path: string): string {
    // remove lash slash if it exists
    path = path.slice(-1) === '/' ? path.slice(0, -1) : path;
    // add first slash if it not exist and not start with http:// or https://
    if (!(path.indexOf('http://') === 0 || path.indexOf('https://') === 0)) {
      path = path.slice(0, 1) !== '/' ? '/' + path : path;
    }
    return path;
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  private formatData(data: APIResponse, options = {}) {
    const opts = { ...defaultOptions, ...options};
    // make sure api response body is match response header as httpErrorResponse
    if ( data.statusCode < 200 || data.statusCode >= 400 ) { throw data; }
    if ( !opts.format ) { return data; }
    return data.result;
  }

  private formatAuthData(data: APIResponse, options = {}) {
    const opts = { ...defaultOptions, ...options};
    // make sure api response body is match response header as httpErrorResponse
    if ( data.statusCode < 200 || data.statusCode >= 400 ) { throw data; }
    if ( !opts.format ) { return data; }
    return data;
  }

  get(path: string, params: HttpParams = new HttpParams(), options = {}): Observable<any> {
    return this.http.get(`${this.apiUrl}${this.normalizePath(path)}`, { params })
      .pipe(catchError(this.formatErrors), map(res => this.formatData(res, options)), distinctUntilChanged());
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.normalizePath(path)}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors), map(res => this.formatData(res)), distinctUntilChanged());
  }

  patch(path: string, body: object = {}): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}${this.normalizePath(path)}`,
      body instanceof FormData ? body : JSON.stringify(body)
    ).pipe(catchError(this.formatErrors), map(res => this.formatData(res)), distinctUntilChanged());
  }

  post(path: string, body: object = {}, options = {}): Observable<any> {
    return this.http.post(
      `${this.apiUrl}${this.normalizePath(path)}`,
      body instanceof FormData ? body : JSON.stringify(body),
      options
    ).pipe(catchError(this.formatErrors), map(res => this.formatData(res, options)), distinctUntilChanged());
  }

  postAuth(path: string, body: object = {}, options = {}): Observable<any> {
    return this.http.post(
      `${this.apiUrl}${this.normalizePath(path)}`,
      body instanceof FormData ? body : JSON.stringify(body),
      options
    ).pipe(catchError(this.formatErrors), map(res => this.formatAuthData(res, options)), distinctUntilChanged());
  }

  delete(path, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}${this.normalizePath(path)}`, { params }
    ).pipe(catchError(this.formatErrors), map(res => this.formatData(res)), distinctUntilChanged());
  }
}
